import React, { useState, useEffect, useRef, useContext } from 'react';
import { HeaderWrapper } from './navbar.elements';
import Image from 'components/UI/atoms/Image';
import DefaultUser from 'components/assets/images/default-picture.png';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  KeyIcon,
  LogOutIcon,
  PadLockIcon,
  SearchIcon,
} from 'components/UI/atoms/Icons';
import { clearLocalStorageData } from 'utils/authUtils';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { useNavigate } from 'react-router-dom';
import Notifications from 'components/UI/molecules/Notifications';
import { checkRoleAuth } from 'utils/authUtils';

import InfiniteScroll from 'react-infinite-scroll-component';

import {
  getNotificationList,
  regenerateChat,
  editIsRead,
} from 'services/notification/notification';
import arrow from '../../assets/images/arrow.png';
import io from 'socket.io-client';
import { toast } from 'react-toastify';

const role = checkRoleAuth();
import { MessageContext } from 'contexts/GlobalStateMessage';

const Popconfirm = ({ onCancel }) => {
  const popconfirmRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popconfirmRef.current &&
        !popconfirmRef.current.contains(event.target)
      ) {
        onCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel]);

  const goToSetupAPIKey = () => {
    navigate('/api-key');
  };

  const handleChangePassword = () => {
    window.location.href = '/change-password';
  };

  const handleLogout = () => {
    clearLocalStorageData();
    window.location.href = '/login';
  };

  return (
    <div
      ref={popconfirmRef}
      className="w-48 absolute z-50 mt-4 bg-white p-4 rounded shadow-lg -left-52"
    >
      <div className="w-full flex flex-wrap gap-y-3">
        <div
          onClick={goToSetupAPIKey}
          className="w-full inline-flex gap-2 items-center cursor-pointer"
        >
          {' '}
          <KeyIcon />{' '}
          <span className="font-medium text-sm">Setup API Keys</span>
        </div>
        <div
          onClick={handleChangePassword}
          className="w-full inline-flex gap-2 items-center cursor-pointer"
        >
          {' '}
          <PadLockIcon />{' '}
          <span className="font-medium text-sm">Edit Password</span>
        </div>
        <div
          onClick={handleLogout}
          className="w-full inline-flex gap-2 items-center cursor-pointer"
        >
          {' '}
          <LogOutIcon /> <span className="font-medium text-sm">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default function Navbar({ hasSidebar = true }) {
  const { setSearchInChat, setMessage } = useContext(MessageContext);
  const nameUser = localStorage.getItem('name');
  const URL_SOCKET = process.env.REACT_APP_SOCKET_URL;
  const navigate = useNavigate();
  const responsiveSide = useContext(GlobalStateContext);
  const [isPopconfirmVisible, setPopconfirmVisible] = useState(false);
  const [isModalNotifVisible, setIsModalNotifVisible] = useState(false);
  const [notifItem, setNotifItem] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const userId = localStorage.getItem('userId');

  const [limitNotification, setLimitNotification] = useState(10);
  const [pageNotification, setPageNotification] = useState(1);
  const [totalNotification, setTotalNotification] = useState(0);
  const [hasNewNotif, setHasNewNotif] = useState(false);
  const [notifId, setNotifId] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [showBigFileNotif, setShowBigFileNotif] = useState(false);

  const socketCall = io(URL_SOCKET, {
    transports: ['websocket'],
  });

  const handleOpenModal = () => {
    // Toggle the Popconfirm dialog
    setPopconfirmVisible(!isPopconfirmVisible);
  };

  const handleCloseModal = () => {
    // Handle the cancel action
    setPopconfirmVisible(false);
  };

  useEffect(() => {
    if (role === 'SUPER_ADMIN') {
      setIsModalNotifVisible(true);
    } else {
      setIsModalNotifVisible(false);
    }
  }, [role]);
  const handleSearchInChat = () => {
    setSearchInChat((prevSearchInChat) => !prevSearchInChat);
  };

  const handleReadNotif = async (id) => {
    try {
      const response = await editIsRead(id);
      if (response) {
        setHasNewNotif(false);
        getNotification();
      }
    } catch (error) {
      toast.error('Failed to read notification');
    }
  };

  const handleRegenerateChat = async (id) => {
    navigate(`chatbot/${id}`);
    setLoadingGenerate(true);
    try {
      const response = await regenerateChat(id);
      if (response) {
        setMessage(response.data.messages);
        handleReadNotif(notifId);
      }
    } catch (error) {
      toast.error('Failed to regenerate chat');
    } finally {
      setLoadingGenerate(false);
    }
  };

  useEffect(() => {
    socketCall.on('connect', () => {
      console.log('socket connect');
      console.log('url socket', URL_SOCKET);
      socketCall.emit('JoinRoomNotification', userId);
    });

    socketCall.on('ReceiveNotification', (msg) => {
      console.log('msg', msg);
      setHasNewNotif(true);
      setNotifItem((prev) => [msg, ...prev]);
      if (msg?.notification_type === 4) {
        setChatId(msg?.chat_id);
        setShowBigFileNotif(true);
      }
    });

    socketCall.on('connect_error', (err) => {
      console.log(`websocket connect_error due to ${err.message}`);
    });

    return () => {
      socketCall.disconnect();
    };
  }, [userId]);

  const getNotification = async () => {
    try {
      const response = await getNotificationList(
        limitNotification,
        pageNotification
      );

      setNotifItem(response.data.notifications);
      setTotalNotification(response.meta.total);
      setPageNotification(pageNotification);
    } catch (error) {
      toast.error('Failed to get notification');
    }
  };

  useEffect(() => {
    getNotification();
    // toast.success(bigFileNotif);

    console.log('chatId', chatId);
  }, [limitNotification, pageNotification]);

  const fetchMoreData = () => {
    setTimeout(() => {
      if (notifItem?.length < totalNotification) {
        setHasMore(true);
        setLimitNotification(limitNotification + 5);
      } else {
        setHasMore(false);
      }
    }, 500);
  };

  const toggleNotif = () => {
    setIsModalNotifVisible(!isModalNotifVisible);
    setHasNewNotif(false);
  };

  const handleCloseNotif = () => {
    setShowBigFileNotif(false);
    handleRegenerateChat(chatId);
  };

  return (
    <React.Fragment>
      <HeaderWrapper>
        <div
          style={{
            width:
              hasSidebar === false
                ? null
                : `calc(100% - ${responsiveSide ? '5rem' : '14rem'})`,
          }}
          className={`bg-fi-blue w-full py-5 px-8 justify-between items-center fixed bg-white border-b-2 z-40 md:flex hidden`}
        >
          <div className="w-full flex flex-wrap justify-end items-center gap-2">
            <div className="mr-2">
              <div className="cursor-pointer" onClick={handleSearchInChat}>
                <SearchIcon width="20" height="20" />
              </div>
            </div>
            <div
              className={`mr-3 relative inline-flex items-center text-sm font-medium text-center ${isModalNotifVisible ? 'text-gray-900 hover:text-white' : 'text-white hover:text-gray-900'} focus:outline-none cursor-pointer`}
              onClick={() => toggleNotif()}
            >
              {role !== 'SUPER_ADMIN' && (
                <>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12.0009 5C13.4331 5 14.8066 5.50571 15.8193 6.40589C16.832 7.30606 17.4009 8.52696 17.4009 9.8C17.4009 11.7691 17.846 13.2436 18.4232 14.3279C19.1606 15.7133 19.5293 16.406 19.5088 16.5642C19.4849 16.7489 19.4544 16.7997 19.3026 16.9075C19.1725 17 18.5254 17 17.2311 17H6.77066C5.47638 17 4.82925 17 4.69916 16.9075C4.54741 16.7997 4.51692 16.7489 4.493 16.5642C4.47249 16.406 4.8412 15.7133 5.57863 14.3279C6.1558 13.2436 6.60089 11.7691 6.60089 9.8C6.60089 8.52696 7.16982 7.30606 8.18251 6.40589C9.19521 5.50571 10.5687 5 12.0009 5ZM12.0009 5V3M9.35489 20C10.0611 20.6233 10.9888 21.0016 12.0049 21.0016C13.0209 21.0016 13.9486 20.6233 14.6549 20"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLineJoin="round"
                    />
                  </svg>
                  {hasNewNotif && (
                    <div className="absolute block w-3 h-3 bg-red-500 border-2 border-white rounded-full -top-0.5 start-3 dark:border-white"></div>
                  )}
                </>
              )}
            </div>
            <div className="relative inline-block">
              {isModalNotifVisible && (
                <div className="absolute mt-2 w-full">
                  <div className="w-[468px] absolute z-50 mt-4 bg-white pb-4 min-w-12 rounded shadow-lg right-5">
                    <div className="block font-large text-left p-4 text-black-700 rounded-t-lg  text-[20px] text-[#101828]">
                      Notifications
                    </div>

                    <InfiniteScroll
                      dataLength={notifItem?.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={
                        <div role="status " className="flex justify-center">
                          <svg
                            aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      }
                      height={400}
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      <Notifications
                        onCancel={() => setIsModalNotifVisible(false)}
                        data={notifItem}
                        handleClickNotif={handleRegenerateChat}
                        setNotifId={setNotifId}
                        notifId={notifId}
                        editIsRead={handleReadNotif}
                        setChatId={setChatId}
                        isLoading={loadingGenerate}
                      />
                    </InfiniteScroll>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="bg-slate-500 h-8 w-8 rounded-full flex items-center justify-center">
            <Image
              src={DefaultUser}
              alt="logo confluidity"
              className="w-5 h-5"
            />
          </div>
          <div className="ml-2">
            <span className="text-nowrap">{nameUser}</span>
          </div>
          {isPopconfirmVisible ? (
            <div onClick={handleCloseModal} className="cursor-pointer">
              <ArrowUpIcon width="24" height="28" />
            </div>
          ) : (
            <div onClick={handleOpenModal} className="cursor-pointer">
              <ArrowDownIcon />
            </div>
          )}
          <div className="relative inline-block">
            {isPopconfirmVisible && (
              <div className="absolute mt-2 w-full">
                <Popconfirm onCancel={handleCloseModal} />
              </div>
            )}
          </div>

          <div
            className={`border border-green-500 bg-[#A6F4C5] rounded-lg w-[480px] h-[74px] p-2 flex justify-between items-center absolute top-20 cursor-pointer transform transition-all  ease-in-out ${
              showBigFileNotif ? 'right-5' : '-right-full duration-500'
            }`}
            onClick={() => handleCloseNotif()}
          >
            <div>
              <p className="text-[14px] font-[400]">
                Document Already Analyzed
              </p>
              <p className="text-[#667085] text-[12px]">
                “Report Confluidity Workshop 2024.pdf” has finish analyzed
              </p>
            </div>

            <Image src={arrow} />
          </div>
        </div>
      </HeaderWrapper>
    </React.Fragment>
  );
}
