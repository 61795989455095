import React, { createContext, useState } from 'react';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [message, setMessage] = useState([]);
  const [searchInChat, setSearchInChat] = useState(false);
  const [formData, setFormData] = useState({
    chat_id: '',
    files: [],
    message: '',
  });

  return (
    <MessageContext.Provider
      value={{
        loadingMessage,
        setLoadingMessage,
        message,
        setMessage,
        searchInChat,
        setSearchInChat,
        formData,
        setFormData,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
